export default {
	data() {
		return {
			selAction: 'upload',
			actions: [{
				key: 'upload',
				label: '同步商品'
			}, {
				key: 'update',
				label: '更新商品'
			}, {
				key: 'offshelf',
				label: '下架商品'
			}, {
				key: 'delsku',
				label: '下架sku'
			}],
			form: {
				channel: '',
				shopifyStores: '',
				pricePlus: 0,
				spus: '',
				details: [],
				skus: ''
			},
			channels: [
				"HEKKA_MALL",
				"HEKKA_MALL_US"
			],
			rules: {
				channel: [{
					required: true,
					message: '站点不能为空',
					trigger: 'change'
				}],
				shopifyStores: [{
					required: true,
					trigger: 'change',
					validator: (rule, value, callback) => {
						if (value.trim() === '') {
							callback(new Error('shopify站点不能为空'))
						} else {
							const v = value.match(/[a-z\-]+/g) || []
							if (v.length === 0) {
								callback(new Error('无合法Shopify站点'))
							} else {
								callback()
							}
						}
					}
				}],
				spus: [{
					required: true,
					trigger: 'change',
					validator: (rule, value, callback) => {
						const v = value.match(/[\w-]+/g) || []
						if (v.length === 0) {
							callback(new Error('Spus不能为空'))
						} else {
							callback()
						}
					}
				}],
				details: [{ 
					type: 'array',
					required: true,
					message: '请至少选择一个更新内容',
					trigger: 'change'
				}],
				skus: [{
					required: true,
					trigger: 'change',
					validator: (rule, value, callback) => {
						const res = value.split('\n').map(v => v.split(',')).reduce((lhs, rhs) => lhs.concat(rhs), []).map(v => v.trim()).filter(v => v !== '')
						if (res.length === 0) {
							callback(new Error('SKU code 不能为空'))
						} else {
							callback()
						}
					}
				}]
			},
			details: [{
				key: 'TITLE',
				label: '标题'
			}, {
				key: 'DESCRIPTION',
				label: '描述'
			}, {
				key: 'SIZE_CHART',
				label: '尺码表'
			}, {
				key: 'PRICE',
				label: '价格'
			}, {
				key: 'CATEGORY',
				label: '品类'
			}, {
				key: 'IMG',
				label: '图片'
			}, {
				key: 'SKU',
				label: 'sku属性(颜色，尺码)'
			}]
		}
	}
}