<template>
	<main-container :showHeader="false">
		<el-card>
			<div>
				<el-form ref="form" :model="form" label-width="130px" :rules="rules">
					<el-form-item label="功能" key="action">
						<el-select v-model="selAction" class="w-100" @change="changedSelAction">
							<el-option v-for="item in actions" :label="item.label" :value="item.key"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="来源站点" prop="channel" v-if="showChannel" key="channel">
						<el-select v-model="form.channel" class="w-100">
							<el-option v-for="item in channels" :label="item" :value="item"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="Shopify站点" prop="shopifyStores" key="store">
						<el-input v-model="form.shopifyStores" type="textarea"></el-input>
					</el-form-item>
					<el-form-item label="价格调整" v-if="showPlusPrice" key="price">
						<el-input-number v-model="form.pricePlus"></el-input-number>
					</el-form-item>
					<el-form-item label="更新内容" prop="details" v-if="showDetails" key="content">
						<el-checkbox-group v-model="form.details" @change="$set(form.details, $event)">
						    <el-checkbox v-for="item in details" :label="item.key" :key="item.key" name="details">{{item.label}}</el-checkbox>
						  </el-checkbox-group>
					</el-form-item>
					<el-form-item label="来源站点商品id" prop="spus" v-if="showSpu" key="spu">
						<el-input v-model="form.spus" type="textarea" :rows="10"></el-input>
					</el-form-item>
					<el-form-item label="SKU code" prop="skus" v-if="showSku" key="sku">
						<el-input v-model="form.skus" type="textarea" :rows="10"></el-input>
					</el-form-item>
					<el-form-item key="button">
						<el-button type="primary" @click="onSubmit">{{selAction|actionLabel(actions)}}</el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-card>
	</main-container>
</template>

<script>
	import {fetchJSONByApis} from '@/apis'
	import Mixin from './mixin.js'
	export default {
		inject: ['adminLayout'],
		mixins: [Mixin],
		computed: {
			showChannel() {
				return this.selAction !== 'delsku'
			},
			showPlusPrice() {
				return this.selAction === 'upload' || (this.selAction === 'update' && !!this.form.details.find(v => v === 'PRICE'))
			},
			showDetails() {
				return this.selAction === 'update'
			},
			showSpu() {
				return this.selAction !== 'delsku'
			},
			showSku() {
				return this.selAction === 'delsku'
			}
		},
		filters: {
			actionLabel(selAction, actions) {
				const v = actions.find(v => v.key === selAction)
				return v && v.label
			}
		},
		methods: {
			changedSelAction() {
				this.$refs.form.clearValidate()
			},
			onSubmit() {
				this.$refs.form.validate(v => {
					if (v) {
						const spus = this.form.spus.match(/[\w-]+/g) || []
						const shopifyStores = this.form.shopifyStores.match(/[a-z0-9\-]+/ig) || []
						const channel = this.form.channel
						const apis = shopifyStores.map(shopifyStore => {
							var payload = {}
							switch (this.selAction) {
								case 'upload':
									//同步商品（带加价）
									const pricePlus = this.form.pricePlus || 0
									payload = {shopifyStore, pricePlus, spus, channel}
									return this.$store.dispatch('shopify/upload', payload)
								case 'update':
									// 更新内容
									const details = this.form.details
									payload = {shopifyStore, details, hekkaSpus: spus, channel}
									if (!!details.find(v => v === 'PRICE')) {
										const pricePlus = this.form.pricePlus || 0
										Object.assign(payload, {pricePlus})
									}
									return this.$store.dispatch('shopify/update', payload)
								case 'offshelf':
									payload = {shopifyStore, hekkaSpus: spus, channel}
									return this.$store.dispatch('shopify/delSpu', payload)
								case 'delsku':
									const skus = this.form.skus.split('\n').map(v => v.split(',')).reduce((lhs, rhs) => lhs.concat(rhs), []).map(v => v.trim()).filter(v => v !== '')
									payload = {shopifyStore, skus}
									return this.$store.dispatch('shopify/delSku', payload)
								default:
									return null
							}
						}).filter(v => v !== null)
						this.adminLayout.showLoading()
						fetchJSONByApis(...apis)
						.then(res => {
							console.info(res)
							this.$showSuccess('您的请求已成功提交')
							this.adminLayout.hideLoading()
						})
						.catch(err => {
							console.error(err)
							this.$showErrMsg(err)
							this.adminLayout.hideLoading()
						})
					}
				})
			}
		}
	}
</script>

<style>
</style>
